import NavBar from '../components/navbar';

function SkillsPage() {
    return ( 
        <>
        <div>
            <NavBar />
        </div>
        </>
     );
}

export default SkillsPage;